

@mixin desktop
  @media (min-width: 480px)
    @content

$color-green: rgb(23, 190, 83)
$color-red: rgb(205, 84, 68)
$color-black: rgb(26, 26, 26)

