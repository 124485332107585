@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500&display=swap')
@import "./common"

*
  box-sizing: border-box

html, body
  padding: 0
  margin: 0
  font-family: 'Montserrat', sans-serif
  font-size: 14px

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

.container
  position: absolute
  width: 100%
  min-height: 100%
  background: $color-black
  background-image: url("./assets/stripes-bg.png")
  background-size: cover
  background-position: center
  background-repeat: no-repeat

  @include desktop
    background-size: 100% 100%

  &.success
    background-image: url("./assets/stripes-bg-green.jpg")
  &.error
    background-image: url("./assets/stripes-bg-red.jpg")

.top-container-wrapper
  &.paused
    background: rgba(0,0,0,.7)

.return-confirmation-wrapper
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: rgba(0,0,0,0.6)
  z-index: 10000

  .return-confirmation
    .return-confirmation-content
      position: relative
      .close
        cursor: pointer
        position: absolute
        height: 30px
        width: 30px
        right: -15px
        top: -15px
        background: url(./assets/return/icon-close.svg) 100% no-repeat

    h2
      line-height: 32px
    color: white
    position: absolute
    max-width: 90%
    width: 500px
    left: 50%
    transform: translateX(-50%)
    top: 20%
    background-image: url(./assets/box-red.png)
    background-size: 100% 100%
    background-repeat: no-repeat
    padding: 50px
    .text
      font-size: 16px
      font-weight: 300
      margin: 20px 0 25px
      line-height: 24px


.top-container
  display: flex
  justify-content: space-between

  @include desktop
    width: 80%
    margin: 15px auto 0

  .link
    display: none
    @include desktop
      display: block
    padding-top: 30px
    a
      font-weight: 500
      display: inline-block
      white-space: nowrap
      letter-spacing: 1.5px
      text-decoration: none
      color: white
      span
        margin-right: 10px

  .image
    display: none
    width: 30%
    @include desktop
      display: block
    img
      display: block
      width: 150px
      margin: 0 auto

  .logo
    width: 50%
    @include desktop
      width: 30%
    img
      display: block
      width: 60%
      margin: 20px
    @include desktop
      img
        width: 200px
        margin: 10px auto

  .status-container
    color: white
    font-weight: 300
    font-size: 16px
    height: 20px
    padding: 25px
    max-width: 50%
    @include desktop
      max-width: 30%

    button
      display: inline-block
      max-width: 100%
      height: 30px
      font-size: 10px
      padding: 5px 10px
      text-overflow: ellipsis
      overflow: hidden

      @include desktop
        height: 40px
        font-size: 12px
        padding: 14px 20px

.content-container
  position: relative
  padding-top: 30px
  @include desktop
    padding-top: 220px

  .paused
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    .overlay
      z-index: 3000
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0
      background: rgba(0,0,0,.7)
    .bubble-container
      z-index: 4000
      width: 400px
      height: 300px
      position: absolute
      left: 50%
      transform: translateX(-50%)
      top: 10%
      @include desktop
        top: 30%
      .bubble
        .icon
          margin-top: 50px
        &:before
          display: none


.button
  cursor: pointer
  text-decoration: none
  border: none
  color: white
  display: inline-block
  background-color: transparent
  background-size: 100% 100%
  height: 40px
  font-size: 12px
  padding: 14px 20px
  font-weight: 600
  letter-spacing: 1.5px
  text-transform: uppercase
  white-space: nowrap
  outline: none
  transition: all .4s
  &.green
    background-image: url(./assets/box-green-wide.png)
    -webkit-box-shadow: 0 8px 10px 2px rgba(23, 190, 83, 0.2)
    -moz-box-shadow: 0 8px 10px 2px rgba(23, 190, 83, 0.2)
    box-shadow: 0 8px 10px 2px rgba(23, 190, 83, 0.2)
    filter: drop-shadow(0 8px 8px rgba(23, 190, 83, .3))
    &:hover
      filter: drop-shadow(0 8px 8px rgba(23, 190, 83, .6))
  &.red
    background-image: url(./assets/box-red-widest.png)
    -webkit-box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2)
    -moz-box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2)
    box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2)
    filter: drop-shadow(0 8px 8px rgba(205, 84, 68, .3))
    &:hover
      filter: drop-shadow(0 8px 8px rgba(205, 84, 68, .6))
  &.purple
    background-image: url(./assets/box-purple-wide.png)
    -webkit-box-shadow: 0 8px 10px 2px rgba(121, 106, 175, 0.2)
    -moz-box-shadow: 0 8px 10px 2px rgba(121, 106, 175, 0.2)
    box-shadow: 0 8px 10px 2px rgba(121, 106, 175, 0.2)
    filter: drop-shadow(0 8px 8px rgba(121, 106, 175, .3))
    &:hover
      filter: drop-shadow(0 8px 8px rgba(101, 86, 155, .6))
  &.dark-red
    background-image: url(./assets/box-dark-red-widest.png)
    -webkit-box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2)
    -moz-box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2)
    box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2)
    filter: drop-shadow(0 8px 8px rgba(205, 84, 68, .3))
    &:hover
      filter: drop-shadow(0 8px 8px rgba(205, 84, 68, .6))


  .icon
    margin-left: 10px
  &.icon-left
    .icon
      margin-left: 0
      margin-right: 10px

.icon
  &:before
    display: inline-block
    width: 12px
    height: 12px
    content: " "
    vertical-align: -1px
  &.arrow-left:before
    background-image: url(./assets/icon-arrow-left.svg)
  &.arrow-right:before
    background-image: url(./assets/icon-arrow-right.svg)

.bubble
  position: absolute
  background-size: 100% 100%
  color: white
  padding: 5%
  text-align: center

  top: 5%
  left: 5%
  height: 90%
  width: 90%

  a
    color: inherit

  .close
    position: absolute
    width: 30px
    height: 30px
    background-image: url(./assets/bubble/icon-close.svg)
    background-size: contain
    top: 30px
    right: 30px
    cursor: pointer

  .icon
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    width: 100px
    height: 50px
    margin: 150px auto 0
    @include desktop
      margin: 100px auto 0

  .header
    font-size: 28px
    font-weight: 400
    margin-top: 10px

  .description
    font-size: 16px
    margin-top: 10px

  &:before
    position: absolute
    content: " "
    background-size: contain
    background-repeat: no-repeat

    height: 70px
    width: 70px
    right: 30px
    bottom: -60px
    transform: scale(1, -1) rotate(90deg)

    @include desktop
      left: -15%
      top: 25%
      width: 20%
      height: 30%
      transform: none
      bottom: auto

  &.success
    &:before
      background-image: url(./assets/bubble/speech-bubble-success.svg)
    background-image: url(./assets/bubble/box-green-tall.png)
    @include desktop
      background-image: url(./assets/bubble/box-green-wide.png)

    .icon
      background-image: url(./assets/bubble/icon-success.svg)

  &.warning
    &:before
      background-image: url(./assets/bubble/speech-bubble-warning.svg)
    background-image: url(./assets/bubble/box-orange-tall.png)
    @include desktop
      background-image: url(./assets/bubble/box-orange-wide.png)

    .icon
      background-image: url(./assets/bubble/icon-warning.svg)

  &.error
    &:before
      background-image: url(./assets/bubble/speech-bubble-error.svg)
    background-image: url(./assets/bubble/box-red-tall.png)
    @include desktop
      background-image: url(./assets/bubble/box-red-wide.png)

    .icon
      background-image: url(./assets/bubble/icon-error.svg)

.selected-artist-label
  background: url(./assets/box-purple-wide.png)
  background-size: 100% 100%
  display: flex
  align-items: center
  color: white
  max-width: 880px
  width: 90%
  margin: 20px auto 50px

  .photo-col
    img
      display: block
      overflow: hidden

      margin: 15px
      width: 50px
      height: 50px
      border-radius: 4px

      @include desktop
        margin: 30px
        width: 100px
        height: 100px
        border-radius: 8px
  .name-col
    padding: 10px
    .name
      font-weight: 500
      font-size: 18px
      @include desktop
        font-size: 22px
    .comment
      margin-top: 10px
      font-size: 12px
      @include desktop
        font-size: 14px

  a
    color: $color-green


.form
  position: relative
  background-image: url(./assets/box-anthracite-wide.png)
  background-size: 100% 100%
  width: 90%
  color: white
  max-width: 880px
  z-index: 1000
  margin: 0 auto 0

  .info
    .artist-label
      position: absolute
      width: 100px
      height: 100px
      right: 0
      top: 0
      @include desktop
        top: -40px
        right: -60px
        width: 150px
        height: 150px
      transform: rotate(10deg)
      background: url(./assets/badge-artist.svg) 100% no-repeat

    position: relative
    padding: 40px
    @include desktop
      margin-left: 35%
      padding: 40px 10px 40px 40px
    h3
      color: $color-green
      font-size: 20px
      margin: 0
      font-weight: 400
    h2
      margin: 10px 0
      font-size: 28px
      font-weight: 600
      @include desktop
        font-size: 38px
        margin: 10px 0 30px
    .info-blocks
      text-align: center
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      @include desktop
        flex-wrap: nowrap
      .block
        background-image: url(assets/box-anthracite-lighter-wide.png)
        background-size: 100% 100%
        padding: 15px 10px
        width: 44%
        margin: 20px 3% 0
        @include desktop
          width: 30%
          margin: 0
        .label
          color: rgb(150, 150, 150)
          font-size: 10px
          white-space: nowrap
          font-weight: 500
        .value
          margin-top: 7px
          color: $color-green
          font-size: 20px
          font-weight: 500
          @include desktop
            font-size: 24px
      .block:last-child
        width: 100%
        @include desktop
          width: 30%

    .text-label
      font-size: 12px
      color: #969696
      span
        font-weight: 500
        color: $color-green

    .supported-artist-block
      position: relative
      margin: 10px auto 20px 0px
      padding: 10px 15px
      background-image: url(./assets/box-anthracite-lighter-wide.png)
      background-size: 100% 100%
      display: flex
      flex-wrap: wrap
      align-items: center
      width: 90%
      .photo-col
        width: 20%
        @include desktop
          width: 10%
        img
          display: block
          width: 100%
          border-radius: 4px
          overflow: hidden
      .name-col
        width: 70%
        @include desktop
          width: 70%
          flex-grow: 1
        padding: 0 10px
        .name
          font-weight: 500
          font-size: 18px
        .mints
          color: #969696
          span
            display: inline-block
            margin-right: 5px
      .button-col
        //margin-top: 10px
        width: 100%
        text-align: center
        @include desktop
          width: 20%
          margin-top: 0
          text-align: right
        .button
          color: #969696
          &:hover
            color: white

      .artist-selection-dropdown
        position: absolute
        z-index: 2000
        width: 100%
        background: black
        top: 0
        left: 0
        max-height: 600px
        overflow-y: auto

        .input-container
          padding: 25px 15px
          input
            display: block
            width: 100%
            font-size: 16px
            font-family: inherit
            line-height: 26px
            color: white
            background: transparent
            border: 1px solid rgb(100,100,100)
            padding: 0 10px

        .artist
          &:hover
            background: rgb(50, 50, 50)
            cursor: pointer
          background-size: 100% 100%
          align-items: center
          width: 100%
          display: flex

          .photo
            width: 20%
            img
              display: block
              width: 40px
              height: 40px
              margin: 10px

          .name
            width: 60%
            font-weight: 500
            font-size: 16px
            padding-left: 20px

          .mints
            padding-right: 20px
            width: 20%
            text-align: right
            font-size: 12px
            div
              font-size: 19px
              font-weight: 500


  .mint-container
    width: 94%
    margin: 10px auto 0
    @include desktop
      margin: 10px 0 0 0
    .input-container
      background-image: url(./assets/input-bg.png)
      background-size: 100% 100%
      display: block
      @include desktop
        display: inline-block
      input
        font-family: inherit
        padding: 0 10px
        height: 40px
        color: rgb(150, 150, 150)
        background: transparent
        border: none
        outline: none
        display: inline-block
        width: 100%

        @include desktop
          width: 140px

    button
      display: block
      width: 100%
      margin: 20px 0
      &.green
        background-image: url(./assets/box-green-widest.png)
      @include desktop
        &.green
          background-image: url(./assets/box-green-wide.png)
        width: auto
        display: inline-block
        margin: 0 0 0 20px

  .status-container
    font-size: 16px
    color: white
    margin-top: 10px
    height: 20px
    overflow: hidden
    text-overflow: ellipsis
    a
      color: white

  .steve
    z-index: 1100
    .whitelist-wrapper
      position: relative
      &.no-label
        display: none
      .whitelist
        display: block
        position: absolute
        width: 150px
        height: 100px
        right: -50px
        top: -50px
        img
          display: block
          width: 100%
      @include desktop
        .whitelist
          width: 100%
          top: -180px
    &.desktop
      display: none
      @include desktop
        display: block
        position: absolute
        left: -2vw
        bottom: -10px
        width: 30%
        transform: none
    &.mobile
      display: block
      width: 60%
      margin: 0 auto
      @include desktop
        display: none
    img
      display: block
      width: 100%

.minted-items-container
  position: relative
  background-image: url(./assets/tru-bg.svg)
  background-size: cover
  width: 85%
  margin: 0 auto
  color: white
  max-width: 680px
  padding: 30px
  @include desktop
    padding: 40px

  .minted-items-header
    display: flex
    justify-content: space-between
    h2
      margin-top: 0
      font-weight: 400
      font-size: 18px
    .return-button
      span
        color: $color-green
        cursor: pointer
        font-size: 12px
        font-weight: 500
        padding-bottom: 1px
        border-bottom: 2px solid $color-green


  .items
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    .item
      .loading
        animation: 3s infinite glow
        position: relative
        .label
          left: 0
          width: 100%
          position: absolute
          text-align: center
          top: 45%

      width: 45%
      @include desktop
        width: 30%
      margin-bottom: 20px
      img
        display: block
        width: 100%
      video
        display: block
        width: 100%

      .video-wrapper
        position: relative
        .return-layout
          position: absolute
          left: 0
          top: 0
          width: 100%
          height: 100%
          background: rgba(205, 84, 68, 0.8)
          display: flex
          align-items: center
          justify-content: center
          img
            display: inline-block
            width: 15%

      .select-member
        margin-top: 10px
        display: flex
        justify-content: space-between
        align-items: center
        cursor: pointer
        user-select: none

        span
          display: block
          &:first-child
            font-weight: 100
            font-size: 12px
          &.select-box
            background: url(./assets/return/box-anthracite.svg) 100% no-repeat
            width: 20px
            height: 20px
            position: relative
            &.selected
              &:before
                content: " "
                background: url(./assets/return/icon-success.svg) 100% no-repeat
                position: absolute
                left: 0
                top: 0
                width: 18px
                height: 18px

  .return-buttons
    button
      margin-right: 20px
    .arrow-left
      margin-left: 0
      margin-right: 10px


@keyframes glow
  from
    opacity: 1

  50%
    opacity: .5

  to
    opacity: 1

.result
  width: 80%
  margin: 100px auto 0
  text-align: center
  @include desktop
    margin-top: 10%
  .steve
    width: 100px
    margin: 0 auto
    img
      display: block
      width: 100%
  .content
    margin-top: 20px
    h2
      margin: 0
      padding: 0
      font-weight: 400
      color: white
      font-size: 30px
      &.green
        color: $color-green
      &.red
        color: $color-red
    h2:first-child
      font-weight: 600
      font-size: 20px
    .comment
      color: white
      font-weight: 100
      font-size: 20px
      margin: 10px 0
    .button
      margin-top: 20px

.backed-artists-container
  margin: 30px auto
  width: 90%
  color: white
  max-width: 880px

  h3
    margin-bottom: 0
  .artists
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    .artist
      background-image: url(./assets/box-anthracite-darker.png)
      background-size: 100% 100%
      align-items: center
      width: 100%
      display: flex
      margin-top: 15px
      cursor: pointer
      transition: transform .5s

      &:hover
        transform: scale(1.05)

      @include desktop
        width: 48%

      .photo
        width: 20%
        img
          display: block
          width: 40px
          height: 40px
          margin: 20px

      .name
        width: 60%
        font-weight: 500
        font-size: 16px
        padding-left: 20px

      .mints
        padding-right: 20px
        width: 20%
        text-align: right
        font-size: 12px
        div
          font-size: 19px
          font-weight: 500
