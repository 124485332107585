@import "./common"

$z: 9999

.modal-container
  .modal-bg
    position: fixed
    z-index: $z
    width: 100%
    height: 100%
    left: 0
    top: 0
    background: rgba(0,0,0,.8)

  .modal
    position: fixed
    z-index: $z+1
    background: white
    border-radius: 10px
    padding: 30px
    width: 300px
    max-width: 100%
    left: 50%
    top: 50%
    transform: translateX(-50%) translateY(-50%)
    .modal-content
      position: relative

      .close
        position: absolute
        right: -10px
        top: -20px
        cursor: pointer
        width: 24px
        height: 24px

      .providers
        .provider
          cursor: pointer
          border: 1px solid rgb(200,200,200)
          border-radius: 5px
          padding: 10px
          margin-bottom: 10px
          display: flex
          align-items: center
          img
            width: 24px
            height: 24px
          label
            margin-left: 20px
          &:hover
            background: rgb(252,252,252)


  .modal-bg, .modal
    opacity: 0
    transition: opacity .3s

  &.show
    .modal-bg, .modal
      opacity: 1
